<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <!-- brand logo -->
            <router-link to="/" class="brand-logo d-flex align-center">
                <v-img :src="appLogo" max-height="200px" max-width="200px" alt="logo" contain class="me-3"></v-img>

                <h2 class="text--primary">
                    {{ appName }}
                </h2>
            </router-link>

            <!--/ brand logo -->

            <v-row class="auth-row ma-0">
                <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
                    <div class="auth-illustrator-wrapper">
                        <!-- triangle bg -->
                        <!--  <img
                            height="362"
                            class="auth-mask-bg"
                            :src="
                                require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)
                            " /> -->
                        <!--   <img
                            height="362"
                            class="auth-mask-bg"
                            :src="require('@/assets/images/login/loginIOcity.png')" /> -->
                        <!-- tree -->
                        <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img>

                        <!-- 3d character -->
                        <div class="d-flex align-center h-full pa-16 pe-0">
                            <v-img
                                contain
                                max-width="100%"
                                height="692"
                                class="auth-3d-group"
                                :src="require('@/assets/images/login/loginIOcity.png')"></v-img>
                        </div>
                    </div>
                </v-col>

                <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
                    <v-row>
                        <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
                            <v-card flat>
                                <v-card-text>
                                    <p class="text-2xl font-weight-semibold text--primary mb-2 text--center">
                                        {{ $t('Login.Welcome') }} 👋🏻
                                    </p>
                                </v-card-text>

                                <!-- login form -->
                                <v-card-text>
                                    <v-form @submit.prevent>
                                        <v-btn
                                            @click="onSubmit('ioCity')"
                                            block
                                            type="submit"
                                            class="mt-6 btn-blue"
                                            x-large
                                            :loading="isLoadingDemo">
                                            Login Demo
                                        </v-btn>
                                        <v-btn
                                            @click="onSubmit('ioParking')"
                                            block
                                            type="submit"
                                            class="mt-6 btn-red"
                                            x-large
                                            :loading="isLoadingParking">
                                            Login IOParking
                                        </v-btn>
                                        <v-btn
                                            @click="onSubmit('ioBus')"
                                            block
                                            type="submit"
                                            class="mt-6 btn-red"
                                            x-large
                                            :loading="isLoadingBus">
                                            Login IOBus
                                        </v-btn>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="8" md="12" lg="12" class="d-flex justify-center">
                            <v-card flat>
                                <v-img contain src="@/assets/images/login/apoiosIOCity.png" max-width="700"></v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    // eslint-disable-next-line object-curly-newline
    import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
    import { ref } from '@vue/composition-api';
    import themeConfig from '@themeConfig';
    import { login, updateMyAccount } from '@/api/auth';
    import auth from '@/mixins/auth.js';

    export default {
        mixins: [auth],
        data() {
            return {
                isLoadingDemo: false,
                isLoadingParking: false,
                isLoadingBus: false,
                form: {
                    identifier: 'demoemail@iotech.pt',
                    password: 'demoPassword',
                },
            };
        },
        methods: {
            async onSubmit(buttonName) {
                try {
                    const response = await login(this.form);
                    this.onLoginSuccess(response.data);

                    let roleID = 0;

                    if (buttonName === 'ioParking') {
                        this.isLoadingParking = true;
                        roleID = 4;
                        this.$vuetify.theme.themes.light.primary = '#ff1053';
                        this.$vuetify.theme.themes.dark.primary = '#ff1053';
                    } else if (buttonName === 'ioBus') {
                        this.isLoadingBus = true;
                        roleID = 3;
                        this.$vuetify.theme.themes.light.primary = '#ff1053';
                        this.$vuetify.theme.themes.dark.primary = '#ff1053';
                    } else if (buttonName === 'ioCity') {
                        this.isLoadingDemo = true;
                        roleID = 1;
                        this.$vuetify.theme.themes.light.primary = '#01ACC0';
                        this.$vuetify.theme.themes.dark.primary = '#01ACC0';
                    }

                    if (roleID !== undefined) {
                        const accountResponse = await updateMyAccount({ role: roleID });
                        this.$store.commit('auth/setUser', accountResponse.data);
                    }

                    this.isLoadingBus = false;
                    this.isLoadingParking = false;
                    this.isLoadingDemo = false;
                    this.$router.push({
                        name: 'Organization',
                        params: { id: this.$store.getters['auth/getUser'].entity.id },
                    });
                } catch (error) {
                    console.log(error);
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error &&
                        error.response.data.error.message === 'Invalid identifier or password'
                    ) {
                        this.isLoading = false;
                    }
                    /* else {
            this.$buefy.toast.open({
                message: 'An error has occurred',
                position: 'is-top',
                type: 'is-danger',
            });
        } */
                }
            },
        },

        setup() {
            const isPasswordVisible = ref(false);

            const email = ref('');
            const password = ref('');

            const socialLink = [
                {
                    icon: mdiFacebook,
                    color: '#4267b2',
                    colorInDark: '#4267b2',
                },
                {
                    icon: mdiTwitter,
                    color: '#1da1f2',
                    colorInDark: '#1da1f2',
                },
                {
                    icon: mdiGithub,
                    color: '#272727',
                    colorInDark: '#fff',
                },
                {
                    icon: mdiGoogle,
                    color: '#db4437',
                    colorInDark: '#db4437',
                },
            ];

            return {
                isPasswordVisible,
                email,
                password,
                socialLink,

                // Icons
                icons: {
                    mdiEyeOutline,
                    mdiEyeOffOutline,
                },

                // themeConfig
                appName: themeConfig.app.name,
                appLogo: themeConfig.app.logo,
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import '@core/preset/preset/pages/auth.scss';

    .btn-blue {
        background-color: #01acc0 !important;
        color: #fff !important;
    }
    .btn-red {
        background-color: #ff1053 !important;
        color: #fff !important;
    }
</style>
